import React from 'react';
import { isMobile } from 'react-device-detect';

const Footer = (props) => {
    console.log("this.props.location.pathname ==>", props)
    return (
        <div>
            {isMobile ?
                <footer>
                    <h6>&copy; Copyright Brevistay Hospitality Pvt. Ltd.</h6>
                    <h6>
                        For Support Mail us  at  <a href="mailto:hotels@brevistay.com" target="_top">hotels@brevistay.com</a>
                    </h6>
                </footer> :
                <footer>
                    <h3>&copy; Copyright Brevistay Hospitality Pvt. Ltd.</h3>
                    <h5>
                        For Support Mail us  at  <a href="mailto:hotels@brevistay.com" target="_top">hotels@brevistay.com</a>
                    </h5>
                </footer>}

        </div>
    );
};

export default Footer;