import { TYPE } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case TYPE.SA_GET_BASIC_SETTINGS:
      return { ...state, response: action.payload };
    case TYPE.WELCOME:
      return { ...state, response: action.payload };
    case TYPE.ADD_GENERAL_COMMENT.POSTß:
      return { ...state, response: action.payload };

    default:
      return state;
  }
}
