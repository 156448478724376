import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.GET_COMMISSIONS_DATA.GET:
            return {...state,response:action.payload}
        case TYPE.UPDATE_COMMISSIONS.POST:
            return { ...state, response: action.payload }
        default:
            return state;
    }
}
