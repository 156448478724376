import { combineReducers } from "redux";
import userResponse from "./user_reducer";
import bookingResponse from "./booking_reducer";
import availabilityResponse from "./availability_reducer";
import rateResponse from "./rates_reducer";
import roomResponse from "./rooms_reducer";
import session from "./session_reducer";
import hotelProfile from "./profile_reducer";
import settingUpdate from "./sa_reducer";
import amentiesResponse from "./amenties_reducers";
import adminDashboardResponse from "./admin_dashboard_reducer";
import bookingCountResponse from "./admin_dashboard_reducer";
import policyUpdateResponse from "./policy_reducer";
import checkinResponse from "./checkIn_reducers";
import updateCheckinResponse from "./checkIn_reducers"
import userAccessResponse from "./userAccess_reducer"
import hotelListingResponse from "./hotel_listing_reducer"
import bankAccountResponse from "./bank_account_reducer";
import adminResponse from "./admin_reducer"
import hotelFinanceResponse from "./finance_reducer";
import userPermissionResponse from "./user_extranet_reducer";
import marketingResponse from './marketing_reducer';
import bucketResponse from './bucket_reducer'
import seoResponse from "./seo_reducer";
import siteUserResponse from './site_users_reducers'
import userDetailResponse from './user_details_reducer'

import experiencesResponse from './experiences_reducers'
import analyticsResponse from './analytics_reducer'
import hotelListWithPopularityResponse from "./edit_popularity_reducer";
import restrictedCouponResponse from "./restricted_coupon_reducer"
import campaignsResponse from "./campaigns_reducer"
import commissionsResponse from "./commissions_reducer";
import refundPoliciesResponse from "./refund_policies_reducer"

const rootReducer = combineReducers({
  userResponse,
  bookingResponse,
  availabilityResponse,
  rateResponse,
  roomResponse,
  session,
  hotelProfile,
  settingUpdate,
  amentiesResponse,
  adminDashboardResponse,
  bookingCountResponse,
  policyUpdateResponse,
  checkinResponse,
  updateCheckinResponse,
  userAccessResponse,
  hotelListingResponse,
  bankAccountResponse,
  adminResponse,
  hotelFinanceResponse,
  userPermissionResponse,
  marketingResponse,
  bucketResponse,
  seoResponse,
  siteUserResponse,
  userDetailResponse,
  experiencesResponse,
  analyticsResponse,
  hotelListWithPopularityResponse,
  restrictedCouponResponse,
  campaignsResponse,
  commissionsResponse,
  refundPoliciesResponse
});

export default rootReducer;
