import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import Layout from "./hoc/layout";
import Auth from "./hoc/auth";
const Login = React.lazy(() => import("./components/Login"));
const AdminDashboard = React.lazy(() => import("./components/AdminDashboard"));
const Profile = React.lazy(() => import("./components/Profile"));
const PageNotFound = React.lazy(() =>
  import("./components/views/pageNotFound")
);
const ManagePassword = React.lazy(() => import("./components/ManagePassword"));
const HotelRegister = React.lazy(() => import("./components/RegisterHotel"));
const AdminRegistration = React.lazy(() =>
  import("./components/AdminRegistration")
);
const UnlistedHotels = React.lazy(() =>
  import("./components/UnlistedClosedHotels")
);
const BulkAccountSearch = React.lazy(() =>
  import("./components/bulkAccountSearch")
);
const BulkAdminSearch = React.lazy(() =>
  import("./components/bulkAdminSearch")
);
const HotelBalanceList = React.lazy(() =>
  import("./components/Finance/hotelBalanceList")
);
const Ledger = React.lazy(() => import("./components/Finance"));
const ExperienceBooking = React.lazy(() =>
  import("./components/ExperienceBooking")
);
const AdminPermissions = React.lazy(() =>
  import("./components/AdminPermissions")
);
const Payment = React.lazy(() => import("./components/Payment"));
const ActivityLogs = React.lazy(() => import("./components/ActivityLogs"));
const SiteUserDetails = React.lazy(() =>
  import("./components/SiteUser/siteUserDetails")
);
const Seo = React.lazy(() => import("./components/SEO"));
const Manageseo = React.lazy(() => import("./components/SEO/manageSeo"));
const Coupon = React.lazy(() => import("./components/Coupons"));
const manageCoupon = React.lazy(() =>
  import("./components/Coupons/manageCoupon")
);
const SiteUser = React.lazy(() => import("./components/SiteUser"));
const HotelAnalytics = React.lazy(() =>
  import("./components/INCAnalytics/hotelanalytics")
);
const DateHotellist = React.lazy(() =>
  import("./components/INCAnalytics/hotelDateList")
);
const Analytics = React.lazy(() => import("./components/INCAnalytics"));
const S3Manager = React.lazy(() => import("./components/S3"));
const S3ManagerNew = React.lazy(() => import("./components/S3/bucket"));
const UploadBucketImg = React.lazy(() =>
  import("./components/S3/uploadBucketImg")
);
const editExperiences = React.lazy(() =>
  import("./components/RegisterHotel/editExperiences")
);
const insufficientPermissions = React.lazy(() =>
  import("./components/views/insufficientPermissions")
);
const updatePopularity = React.lazy(() =>
  import("./components/BulkPopularity")
);
const Cityanalytics = React.lazy(() =>
  import("./components/INCAnalytics/cityanalytics")
);
const HotelInventory = React.lazy(() => import("./components/ManageInventory"));
const CityDetailAnalytics = React.lazy(() =>
  import("./components/INCAnalytics/Citydetailanalytics")
);
const MarketingCampaign = React.lazy(() =>
  import("./components/MarketingCampaign")
);
const ManageCampaign = React.lazy(() =>
  import("./components/MarketingCampaign/manageCampaign")
);

const Routes = (props) => {
  return (
    <Layout>
      <Suspense fallback={<Loader active />}>
        <Switch>
          <Route
            path="/hotel-balance-list"
            exact
            component={Auth(HotelBalanceList, true)}
          />
          <Route
            path="/register-hotel/:id"
            exact
            component={Auth(HotelRegister, true)}
          />
          <Route path="/profile" exact component={Auth(Profile, true)} />
          <Route
            path="/admin-registration"
            exact
            component={Auth(AdminRegistration, true)}
          />
          <Route
            path="/password-change"
            exact
            component={Auth(ManagePassword, true)}
          />
          <Route
            path="/force-password-change"
            exact
            component={Auth(ManagePassword, false)}
          />
          <Route path="/login" exact component={Auth(Login, false)} />
          <Route
            path="/experiences"
            exact
            component={Auth(ExperienceBooking, true)}
          />
          <Route
            path="/bulk-account-search"
            exact
            component={Auth(BulkAccountSearch, true)}
          />
          <Route
            path="/bulk-admin-search"
            exact
            component={Auth(BulkAdminSearch, true)}
          />
          <Route path="/payment/:error" exact component={Payment} />
          <Route
            path="/admin-dashboard"
            exact
            component={Auth(AdminDashboard, true)}
          />
          <Route
            path="/activity-logs"
            exact
            component={Auth(ActivityLogs, true)}
          />
          <Route
            path="/user-permissions"
            exact
            component={Auth(AdminPermissions, true)}
          />
          <Route
            path="/unlisted-hotels"
            exact
            component={Auth(UnlistedHotels, true)}
          />
          <Route path="/finance" exact component={Auth(Ledger, true)} />
          <Route path="/coupon" exact component={Auth(Coupon, true)} />
          <Route
            path="/coupon/new-coupon"
            exact
            component={Auth(manageCoupon, true)}
          />
          <Route
            path="/coupon/edit-coupon/:key"
            exact
            component={Auth(manageCoupon, true)}
          />
          <Route path="/seo" exact component={Auth(Seo, true)} />
          <Route
            path="/seo/edit-seo/:key"
            exact
            component={Auth(Manageseo, true)}
          />
          <Route path="/s3/" exact component={Auth(S3Manager, true)} />
          <Route
            path="/s3/:type/upload"
            exact
            component={Auth(UploadBucketImg, true)}
          />
          <Route
            path="/s3/:type?/:key?"
            exact
            component={Auth(S3ManagerNew, true)}
          />
          <Route path="/site-user" exact component={Auth(SiteUser, true)} />
          <Route
            path="/site-user/site-user-details/:key"
            exact
            component={Auth(SiteUserDetails, true)}
          />

          <Route path="/analytics" exact component={Auth(Analytics, true)} />
          <Route
            path="/editexperiences/:id"
            exact
            component={Auth(editExperiences, true)}
          />
          <Route
            path="/permissions-denied"
            exact
            component={Auth(insufficientPermissions, true)}
          />
          <Route
            path="/analytics/hotel-analytics"
            exact
            component={Auth(HotelAnalytics, true)}
          />
          <Route
            path="/analytics/date-hotel-list"
            exact
            component={Auth(DateHotellist, true)}
          />
          <Route
            path="/analytics/city-detail-analytics"
            exact
            component={Auth(CityDetailAnalytics, true)}
          />
          {/* <Route path="/internal/:category?/:type?/:key?" exact component={Auth(Marketing, true)} /> */}
          <Route
            path="/analytics/city-analytics"
            exact
            component={Auth(Cityanalytics, true)}
          />
          <Route
            path="/update-popularity"
            exact
            component={Auth(updatePopularity, true)}
          />
          <Route
            path="/hotel-inventory"
            exact
            component={Auth(HotelInventory, true)}
          />
          <Route
            path="/marketing-campaign"
            exact
            component={Auth(MarketingCampaign, true)}
          />
          <Route
            path="/marketing-campaign/edit-campaign/:key"
            exact
            component={Auth(ManageCampaign, true)}
          />
          <Route path="/" exact component={Auth(Profile, true)} />
          <Route component={Auth(PageNotFound, true)} />
        </Switch>
      </Suspense>
    </Layout>
  );
};
export default Routes;
