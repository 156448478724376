import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.LOGIN:
            return { ...state, response: action.payload }
        case TYPE.LOGOUT:
            return { ...state, response: action.payload }
        case TYPE.CHANGE_PASSWORD:
            return { ...state, response: action.payload }
        case TYPE.SEND_OTP_TO_CHANGE_PASSWORD:
            return { ...state, response: action.payload }
        case TYPE.CHANGE_PASSWORD_USING_OTP:
            return { ...state, response: action.payload }
        case TYPE.SESSION_VALID:
            return { ...state, response: action.payload }
        case TYPE.VERIFY_OTP_LOGIN:
            return { ...state, response: action.payload }

        default:
            return state;
    }
}

