import { TYPE } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case TYPE.GET_BOOKING_HISTORY:
      return { ...state, response: action.payload };
    case TYPE.GET_PENDING_BOOKINGS:
      return { ...state, response: action.payload };
    case TYPE.UPDATE_CHECKIN_STATUS:
      return { ...state, response: action.payload };
    case TYPE.DENY_BOOKING:
      return { ...state, response: action.payload };
    case TYPE.GET_PACKAGE_BOOKING_HISTORY:
      return { ...state, response: action.payload };
    case TYPE.BOOKING_VOUCHER:
      return { ...state, response: action.payload };
    default:
      return state;
  }
}
