import { TYPE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.GET_ADMIN_BOOKINGS:
            return { ...state, response: action.payload }
        case TYPE.GET_BOOKING_COUNT:
            return { ...state, response: action.payload }
        case TYPE.GET_BOOKING_ID_DETAILS:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_CHECKIN_COMPLETE:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_CANCEL_CHECKIN:
            return { ...state, response: action.payload }
         //PENDING BOOKING
        case TYPE.REVERIFY_BOOKING_TRANSACTION:
            return {...state, response:action.payload}
        case TYPE.CANCEL_BOOKING:
            return { ...state, response: action.payload }
        case TYPE.RESEND_COMMUNICATIONS:
            return { ...state, response: action.payload }
        case TYPE.BOOKING_TRANSFER:
            return { ...state, response: action.payload }
        case TYPE.ADD_UPDATE_CANCELLATION_COMMENT:
            return { ...state, response: action.payload }
        case TYPE.GET_ADMIN_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.GET_PACKAGE_COUNT:
            return { ...state, response: action.payload }
        case TYPE.RESEND_COMMUNICATIONS_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.GET_PACKAGE_ID_DETAILs:
            return { ...state, response: action.payload }
        case TYPE.UPDATE_PACKAGE_CHECKIN_COMPLETE:
            return { ...state, response: action.payload }
        case TYPE.CANCEL_PACKAGE:
            return { ...state, response: action.payload }
        case TYPE.CREDIT_TRANSFER:
            return { ...state, response: action.payload }
        // pending
        case TYPE.PACKAGE_CANCELLATION_COMMENT:
            return { ...state, response: action.payload }
        //CREATE_TRC_COUPON
        case TYPE.CREATE_TRC_COUPON.POST:
            return {...state, response:action.payload}
        case TYPE.SEARCH_COUPON:
            return {...state, response: action.payload}
        default:
            return state;
    }
}

