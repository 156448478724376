import { TYPE } from "../actions/types";
export default function (state = {}, action) {
    switch (action.type) {
        case TYPE.HOTELS_POPULARITY_LIST:
            return { ...state, response: action.payload }
            case TYPE.BULK_POPULARITY_UPDATE.PUT:
                return { ...state, response: action.payload }
        default:
            return state
    }



}