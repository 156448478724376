// const serverHost = "https://www.brevistay.com";
const serverHost = "https://admin.brevistay.com/adex";
const devServer1 = "https://uat-admin.brevistay.com";
const devServer2 = "https://2.test.brevistay.com";
const devServer3 = "https://3.test.brevistay.com";


export const debug = 1// CHANGE THIS TO 0 before deploying to live server
//(api,internalRoute)=> 0->(LIVE, LIVE) 1->(TEST,STAGING) 2->(TEST ,LOCALHOST)

const HOST = debug == 0 ? serverHost : debug == 1 ? devServer1 :debug == 2 ? devServer2: devServer3;
export const HOTEL_API = HOST + "/wss/hotel";
export const SUPER_ADMIN_API = HOST + "/api/sa";
export const FINANCE = HOST + "/finance";
export const MARKETING = HOST + "/marketing"
export const USER_DATA = "user_data";
export const PAYMENT = HOST + "/payments"
export const SELECTED_HOTEL_ID = "selected_hotel_id";
export const SELECTED_HOTEL_NAME = "selected_hotel_name";
export const ANALYTICS = HOST + "/analytics";

export const API_RESPONSE = {
  SESSION_ERROR: "401",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};
export const SESSION_ERROR = "session_error";

