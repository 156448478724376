import React, { Component } from "react";
import {
  Segment,
  Image,
  Breadcrumb,
  Icon
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Suspense, lazy } from 'react';
import {
  USER_DATA,
  SELECTED_HOTEL_ID,
  SELECTED_HOTEL_NAME
} from "../../../constants";
import queryString from 'query-string';
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";
import { connect } from "react-redux";
import {
  isMobile
} from "react-device-detect"
import * as Constants from '../../../constants';

const HeaderComponentWeb = React.lazy(() => import('../Header/headerComponent'));
const HeaderComponentMobile = React.lazy(() => import('../Header/headeComponent_m'));

class Header extends Component {
  state = {
    activeItem: "/profile",
    visible: false,
    label: "",
    icon: "",
    financeMenuVisible: false,
    userType: "",
    isAdminAvailable: false
  };

  getMenuList = () => {
    let unlistedClosedLabel = ""
    let permissions = this.props.session.data.permissions
    let menuList = [];
    let subMenulist = [];
    let isHotelClosed = 0
    if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 1) {
      unlistedClosedLabel = ["Unlisted/Closed Hotels"]
      isHotelClosed = 1
    }
    else if (permissions.uep_hotel_listing == 1 && permissions.uep_closed_hotels == 0) {
      unlistedClosedLabel = "Unlisted Hotels"
    }
    else if (permissions.uep_hotel_listing == 0 && permissions.uep_closed_hotels == 1) {
      unlistedClosedLabel = "Closed Hotels"
    }

    if (permissions.uep_hotel_profile == "1") {
      subMenulist.push({
        label: "Hotel Profile",
        pathValue: "/profile",
        icon: "user",
        type: "admin",
        isAvailableForUser: true
      })
    }
    if (permissions.uep_marketing == "1") {
      subMenulist.push({
        label: "Coupons",//hooks
        pathValue: "/coupon",
        icon: "address card",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      },
      );
    }
    if (permissions.uep_marketing == "1") {
      subMenulist.push({
        label: "SEO",//hooks
        pathValue: "/seo",
        icon: "linkify",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      })
    }
    if (permissions.uep_marketing == "1") {

      subMenulist.push({
        label: "Campaign",//hooks
        pathValue: "/marketing-campaign",
        icon: "server",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      })
    }
    if (permissions.uep_analytics != "0") {
      subMenulist.push(
        {
          label: "Analytics",//hooks
          pathValue: "/analytics",
          icon: "chart bar",
          color: "#3377ab",
          type: "admin",
          isAvailableForUser: true
        },
      )
    }
    if (permissions.uep_s3_bucket == "1") {
      subMenulist.push(
        {
          label: "Manage S3",//hooks
          pathValue: "/s3",
          icon: "bitbucket",
          color: "#3377ab",
          type: "admin",
          isAvailableForUser: true
        },
      )
    }
    if (permissions.uep_admin_dashboard == "1") {
      subMenulist.push({
        label: "Bookings Dashboard",
        pathValue: "/admin-dashboard",
        icon: "dashboard",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      },

      );
    }
    if (permissions.uep_a_site_user != "0") {
      subMenulist.push({
        label: "Site User", //hooks
        pathValue: "/site-user",
        icon: "object group outline",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true,
      })
    }



    if (permissions.uep_hotel_listing == "1" || permissions.uep_closed_hotels == "1") {
      subMenulist.push({
        label: unlistedClosedLabel,
        pathValue: "/unlisted-hotels",
        icon: "building",
        color: "#3377ab",
        type: "admin",
        isHotelClosed: isHotelClosed,
        isAvailableForUser: true
      });
    }
    if (permissions.uep_finance_inhouse == "1" || permissions.uep_finance == "1") {
      subMenulist.push({
        label: "Finance",
        pathValue: "/finance",
        icon: "money bill alternate",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      })
    }
    if (permissions.uep_finance_inhouse == "1") {
      subMenulist.push({
        label: "Payouts",
        pathValue: "/hotel-balance-list", //check Route
        icon: "handshake",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      }

      );
    }

    if (permissions.uep_activity_logs == "1") {
      subMenulist.push(
        {
          label: "Activity Logs",
          pathValue: "/activity-logs",
          icon: "history",
          color: "#3377ab",
          type: "admin",
          isAvailableForUser: true
        }
      );
    }
    // for Activity Log

    if (permissions.uep_user_management == "1") {
      subMenulist.push({
        label: "User Permission",
        pathValue: "/user-permissions",
        icon: "users",
        color: "#3377ab",
        type: "admin",
        isAvailableForUser: true
      },
      );
    }


    subMenulist.push({
      label: "Change Password",
      pathValue: "/password-change",
      icon: "key",
      color: "#3377ab",
      type: "admin",
      isAvailableForUser: true
    },
    );

    return subMenulist;
  };

  handleItemClick = (e, { name, label, value, type }) => {
    console.log("type===>", name, value, label, e.target)

      if (name !== "/password-change") {
        localStorage.setItem("path_name", name);
      }
      this.setState({ visible: false });
      this.setState({ activeItem: name });
      this.setState({ label: label });
      this.setState({ icon: value })
      // this.setState({ userType: type })
      this.props.history.push(name);

  };

  handleChangedValue = (event, valueSet) => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let selectedHotel = userData.hotels.find(
      hotel => hotel.hotel_id === valueSet.value
    );
    localStorage.setItem(SELECTED_HOTEL_ID, selectedHotel.hotel_id);
    localStorage.setItem(SELECTED_HOTEL_NAME, selectedHotel.hotel_name);
    this.props.history.push("/redirect");
    setTimeout(() => {
      this.props.history.push("/bookings");
    }, 2);
    this.setState({
      activeItem: this.getMenuList()[0].pathValue,
      label: this.getMenuList()[0].label,
      icon: this.getMenuList()[0].icon
    });
  };

  logoutUser = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    let data = {
      "login_user_id": userData.login_user_id,
      "login_user_name": userData.login_user_name,
      "login_user_type": userData.user_access_type
    }
    this.props.dispatch(logout(data));
    this.props.dispatch(clearSession());
    this.props.history.push("/login");
  };

  isUserHeader = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData && userData.token && userData.token.length > 20;
  };

  getHotelList = () => {
    let userData = JSON.parse(localStorage.getItem(USER_DATA));
    return userData.hotels.map(hotel => ({
      value: hotel.hotel_id,
      text: hotel.hotel_name,
      key: hotel.hotel_name
    }));
  };

  sidebarOpenClick = () => {
    this.setState({ visible: !this.state.visible });
  };
  sidebarCloseClick = () => {
    this.setState({ visible: false });
  };
  changePassword = () => {
    this.props.history.push("/password-change");
  }

  formatType = (routeType) => {
    const words = routeType.split("-");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ")
  }
  splitPrefix = (value) => {
    const prefix = value.key;
    if (typeof (prefix) == 'undefined') {
      return '';
    } else {
      const splitStr = prefix.split('/');
      return splitStr;
    }
  }


  handleBreadcrumb = () => {
    let substring = this.props.location.pathname.substring(1);
    let temparr = substring.split("/")
    let breadcrumbArray = []
    if (temparr[0] == "") {
      breadcrumbArray.push(
        <>
          <Breadcrumb.Section
            style={{
              fontWeight: "bold",
              fontSize: "1em"
            }}
          >
            {/* {item[0].toUpperCase() + item.substr(1)} */}
            {this.getMenuList()[0].label}
          </Breadcrumb.Section>
        </>
      )
    } else {
      temparr.map((item, i) => {
        breadcrumbArray.push(
          <>
            {i != 0 || breadcrumbArray.length > 1 ? <Icon name="angle right" color="white" /> : null}
            <Breadcrumb.Section
              style={{
                fontWeight: i != 0 ? null : "bold",
                fontSize: i != 0 ? "0.8em" : "1em",
                color: "white"
              }}
              onClick={() => console.log("zgasd")}
            >
              {this.formatType(item)}
              {/* {item[0].toUpperCase() + item.substr(1)} */}
            </Breadcrumb.Section>
          </>
        )
      })
    }
    return breadcrumbArray
  }

  render() {
    const { activeItem } = this.state;
    console.log("Route", this.props)

    return (
      <div>
        {Constants.debug == 1 ?
          <div style={{ fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}>Test 1</div> :
          Constants.debug == 2 ? <div style={{ fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}>Test 2</div> :
          Constants.debug == 3 ? <div style={{ fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}>Test 3</div>: null}

        {this.props.session && this.props.session.isSessionValid ? (

          isMobile ?


            <div>
              <Suspense fallback>
                <HeaderComponentMobile
                  logoutUser={this.logoutUser}
                  changePassword={this.changePassword}
                  getHotelList={this.getHotelList}
                  getMenuList={this.getMenuList}
                  handleItemClick={this.handleItemClick}
                  handleChangedValue={this.handleChangedValue}
                  sidebarOpenClick={this.sidebarOpenClick}
                  sidebarCloseClick={this.sidebarCloseClick}
                  state={this.state}
                  {...this.props} />
              </Suspense>
            </div >
            :
            <div>
              < Suspense fallback>
                <HeaderComponentWeb
                  logoutUser={this.logoutUser}
                  // handleBreadcrumb={this.handleBreadcrumb()}
                  changePassword={this.changePassword}
                  sidebarOpenClick={this.sidebarOpenClick}
                  sidebarCloseClick={this.sidebarCloseClick}
                  getHotelList={this.getHotelList}
                  getMenuList={this.getMenuList}
                  handleItemClick={this.handleItemClick}
                  handleChangedValue={this.handleChangedValue}
                  permissions={this.props.session.data.permissions}
                  state={this.state}
                  {...this.props} />
              </Suspense>

            </div>

        ) : (
          <Segment.Group className="header-config">
            {/* <Segment textAlign="center" className="colorPrimary">
              <span>
                <Image
                  wrapped
                  verticalAlign="middle"
                  src="https://site-img-res-new.s3.ap-south-1.amazonaws.com/extranet/extranet-admin.png"
                  size="small"
                />
                <p style={{ color: "#fff" }}> Admin </p>
              </span>
            </Segment> */}
          </Segment.Group>
        )
        }
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session.response
  };
};
export default connect(mapStateToProps)(withRouter(Header));