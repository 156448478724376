import { TYPE } from "../actions/types";
const initialState = {
    faq_list: [],
    seo_list: [],
    seoStatus: "",
    searchButtonLoading: false,
    seoCityList: [],
    cityContent: [],
    faqResponse: false,
    isSeoUpdated: false,
    newSeoCreatedResponse: [],
    seoDeleteResponse: {},
    isSeoImageUpdated: "",
    seoImageUploadResponse: {},
    faqDeleteResponse:{}
}

export default function (state = initialState, action, response = action.payload) {
    state.isSeoUpdated = false
    switch (action.type) {
        case TYPE.SEO.GET:
            state.searchButtonLoading = true
            state.seoStatus = response.status
            if (response.status == "SUCCESS") {
                state.searchButtonLoading = false
                if (response.seoData[0].faq_id) {
                    state.faq_list = response.seoData
                }
                else {
                    state.seo_list = response.seoData
                }
            }
            else {
                state.searchButtonLoading = false
            }
            return { ...state };
        case TYPE.SEO.POST:
            state.newSeoCreatedResponse = response
            return { ...state };
        case TYPE.SEO_CITIES.GET:
            return {
                ...state,
                seoCityList: action.payload.city_list.map(hotel => ({
                    value: hotel.city_id,
                    text: hotel.city_name,
                    key: hotel.city_name
                })),
            };
        case TYPE.SEO.PUT:
            if (response.status == "SUCCESS") {
                state.isSeoUpdated = "SUCCESS"
            } else {
                state.isSeoUpdated = "ERROR"
            }
            return { ...state, response };
        case TYPE.SEO_CITY_CONTENT.GET:
            if (response.status == "SUCCESS") {
                state.cityContent = response.seo_city_content
            }
            return { ...state };
        case TYPE.FAQS.POST:
            state.faqResponse = response
            return { ...state };
        case TYPE.FAQS.PUT:
            state.faqResponse = response
            return { ...state };
        case "clear_seo_state":
            state.seo_list = []
            state.seoCityList = []
            state.seoStatus = ""
            state.newSeoCreatedResponse = []
            
            state.seoImageUploadResponse = {}
            state.faqDeleteResponse = {}
            return { ...state };
        case "clear_seo_data":
            state.newSeoCreatedResponse = []
            state.faqResponse = []
            return { ...state };
        case "clear_faq_state":
            state.cityContent = []
            state.faqResponse = []
            state.isSeoUpdated = false
            state.faq_list = []
            return { ...state };
        case TYPE.SEO.DELETE:
            state.seoDeleteResponse = response
        case TYPE.UPLOAD_SEO_IMAGE.POST:
            state.seoImageUploadResponse = response
            return { ...state }
        case TYPE.DELETE_FAQ.POST:
            state.faqDeleteResponse = response
            return {...state}
        default:
            return state;
    }
}